import React from "react";

import "./item-table.css";


const slotDict = {
  head_item: "Head",
  neck_item: "Neck",
  shoulder_item: "Shoulder",
  back_item: "Back",
  chest_item: "Chest",
  wrist_item: "Wrist",
  hand_item: "Hand",
  waist_item: "Waist",
  leg_item: "Leg",
  foot_item: "Foot",
  finger1_item: "Ring",
  finger2_item: "Ring",
  trinket1_item: "Trinket",
  trinket2_item: "Trinket",
  mainhand_item: "Main-Hand",
  offhand_item: "Off-Hand",
  range_item: "Ranged"
}

export default class ItemTable extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemset !== this.props.itemset) {
      // eslint-disable-next-line no-undef
      $myDbTooltips.refreshLinks();
    }
  }

  render() {
    const { itemset } = this.props;
    console.log(itemset)

    // If all entries in itemset are 0, hide
    let allZero = true;
    for (const slot of Object.keys(itemset)) {
      if (slotDict[slot] && itemset[slot]) {
        allZero = false;
        break;
      }
    }
    if (allZero) {
      return null;
    }

    return (
      <article>
        <table>
          <thead>
          <tr>
            <th><strong>Item</strong></th>
            <th className="col-center"><strong>Slot</strong></th>
          </tr>
          </thead>
          <tbody>
          {Object.keys(itemset).map(slot => {
            if (slotDict[slot] && itemset[slot]) {
              return (
                <tr key={slot}>
                  <td>
                    <a href={`https://warcraftdb.com/classic/item/${itemset[slot]}`}>{slotDict[slot]}</a>
                  </td>
                  <td className="col-center">{slotDict[slot]}</td>
                </tr>
              )
            }
          })}
          </tbody>
        </table>
      </article>
    )
  }
}
