import React from "react";

const wowheadbaselink = "https://warcraftdb.com/classic/item/";

function GetItemTooltipHtml(item_info) {
  if (item_info == null) return null;
  var wowheadlink =
    '<a href="' +
    wowheadbaselink +
    item_info._attributes.id +
    '" target="_blank">View on WarcraftDB</a>';
  return item_info.htmlTooltip._cdata + wowheadlink;
}

export default class Tooltip extends React.Component {
  static setTooltip(item_info, enchant) {
    var html = GetItemTooltipHtml(item_info, enchant);
    var visible =
      html != null && this.state.tooltiphtml != html ? " tooltip-visible" : "";
    html = visible == "" ? "" : html;
    this.setState({
      tooltiphtml: html,
      enchanthtml: enchant,
      visible: visible
    });
  }
  constructor(props) {
    super(props);
    this.state = { tooltiphtml: null, enchanthtml: null, visible: "" };
    Tooltip.setTooltip = Tooltip.setTooltip.bind(this);
  }
  render() {
    return (
      <div className="Tooltip">
        <div
          className={"qe0 wowhead-tooltip" + this.state.visible}
          dangerouslySetInnerHTML={{ __html: this.state.tooltiphtml }}
        />
        <div
          className={"qe0 wowhead-tooltip" + this.state.visible}
          dangerouslySetInnerHTML={{ __html: this.state.enchanthtml }}
        />
      </div>
    );
  }
}
