import React from "react";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import "./selection.css";

import Icon from "../icon";
import BisList from "../bislist";

const stageValuesTest = [
  { value: 1, text: "1 (pre-raid)" },
  { value: 2, text: "2 (pre-raid)" },
  { value: 3, text: "2" },
  { value: 4, text: "3" },
  { value: 5, text: "4" },
  { value: 6, text: "5" },
  { value: 7, text: "6" }
];
const classValues = [
  "druid",
  "hunter",
  "mage",
  "paladin",
  "priest",
  "rogue",
  "shaman",
  "warlock",
  "warrior"
];

var itemSetId = 0;

class Selection extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClassClick = this.handleClassClick.bind(this);
    this.handleSpecClick = this.handleSpecClick.bind(this);
    this.handleFactionClick = this.handleFactionClick.bind(this);
    this.handleRaceClick = this.handleRaceClick.bind(this);
    this.handleShowStageModal = this.handleShowStageModal.bind(this);
    this.handleCloseStageModal = this.handleCloseStageModal.bind(this);

    const query = this.parseQuery(props.location.search)
    this.state = {
      stageSelected: query['class'] ? 1 : null,
      classSelected: query['class'] || null,
      specSelected: query['spec'] ? `${query['class']}-${query['spec']}` : null,
      factionSelected: null,
      raceSelected: null,
      setItemSet: this.props.setItemSet,
      setTitle: this.props.setTitle,
      showStageModal: false
    };
  }
  componentDidMount() {
    this.checkIfOneSetOnly()
  }
  handleCloseStageModal() {
    this.setState({ showStageModal: false });
  }

  handleShowStageModal() {
    this.setState({ showStageModal: true });
  }
  handleChange(stageSelected) {
    this.setState({ stageSelected });
  }
  handleClassClick(classSelected) {
    this.setState({
      classSelected: classSelected.value,
      specSelected: null,
      factionSelected: null
    });
  }
  handleSpecClick(specSelected) {
    this.setState({ specSelected: specSelected.value, factionSelected: null });
  }
  handleFactionClick(factionSelected) {
    this.setState({ factionSelected: factionSelected.value });
  }
  handleRaceClick(raceSelected) {
    this.setState({ raceSelected: raceSelected.value });
  }
  checkIfOneSetOnly() {
    var itemSet = [];

    var fv = this.factionValues();
    var factionSelected = this.state.factionSelected;
    if (fv.length == 0) {
      factionSelected = null;
    }

    var rv = this.raceValues();
    var raceSelected = this.state.raceSelected;
    if (rv.length == 0) {
      raceSelected = null;
    }

    for (var i = 0; i < BisList.data.length; i++) {
      if (
        BisList.data[i].stage == this.state.stageSelected &&
        BisList.data[i].wowclass == this.state.classSelected &&
        BisList.data[i].wowspec == this.state.specSelected &&
        BisList.data[i].faction == factionSelected &&
        BisList.data[i].race == raceSelected
      )
        itemSet.push(BisList.data[i].id);
    }
    if (itemSet.length == 1 && itemSetId != itemSet[0]) {
      itemSetId = itemSet[0];
      this.state.setItemSet(itemSet[0]);
    }
  }
  specValues() {
    var specValues = [];

    for (var i = 0; i < BisList.data.length; i++) {
      if (
        BisList.data[i].stage == this.state.stageSelected &&
        BisList.data[i].wowclass == this.state.classSelected &&
        !specValues.includes(BisList.data[i].wowspec)
      )
        specValues.push(BisList.data[i].wowspec);
    }

    if (specValues.length == 1 && specValues[0] == null) return [];
    return specValues;
  }
  factionValues() {
    var factionValues = [];

    for (var i = 0; i < BisList.data.length; i++) {
      if (
        BisList.data[i].stage == this.state.stageSelected &&
        BisList.data[i].wowclass == this.state.classSelected &&
        BisList.data[i].wowspec == this.state.specSelected &&
        BisList.data[i].faction != null
      )
        factionValues.push(BisList.data[i].faction);
    }
    return factionValues;
  }
  raceValues() {
    var raceValues = [];

    for (var i = 0; i < BisList.data.length; i++) {
      if (
        BisList.data[i].stage == this.state.stageSelected &&
        BisList.data[i].wowclass == this.state.classSelected &&
        BisList.data[i].wowspec == this.state.specSelected &&
        BisList.data[i].race != null
      )
        raceValues.push(BisList.data[i].race);
    }
    return raceValues;
  }
  componentDidUpdate() {
    this.checkIfOneSetOnly();
  }
  generateLink (url, params, key, value) {
    if (key === 'class') {
      delete params['spec']
      delete params['faction']
    } else if (key === 'spec') {
      // Remove class from spec slug to make it shorter
      value = value.replace(`${params['class']}-`, '')
      delete params['faction']
    }
    params[key] = value

    const query = []
    for (const key of Object.keys(params)) query.push(`${key}=${params[key]}`)
    return `${url}?${query.join('&')}`
  }
  parseQuery (search) {
    const query = {}
    if (search) {
      const split = search.split('?')
      if (split.length > 1) {
        for (const queryParam of split[1].split('&')) {
          const paramSplit = queryParam.split('=')
          query[paramSplit[0]] = paramSplit[1]
        }
      }
    }
    return query
  }
  render() {
    const { url } = this.props.match
    const query = this.parseQuery(this.props.location.search)
    const { history } = this.props

    var stages = stageValuesTest.map((stage, index) => {
      return (
        <Button
          key={stage.value}
          value={stage.value}
          variant="outline-warning"
          active={this.state.stageSelected === stage.value}
          onClick={() => {
            this.handleChange(stage.value)
          }}
        >
          {stage.text}
        </Button>
      );
    });
    var classs = classValues.map((value, index) => {
      return (
        <OverlayTrigger
          key={index}
          placement="top"
          delay={1500}
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="d-inline-block">
            <Link to={`?class=${value}`}>
              <Icon
                key={index}
                slot={value}
                onClick={() => {
                  this.handleClassClick({ value })
                }}
                iconSelected={this.state.classSelected}
              />
            </Link>
          </div>
        </OverlayTrigger>
      );
    }, this);

    var specs = this.specValues().map((value, index) => {
      return (
        <OverlayTrigger
          key={index}
          placement="top"
          delay={1500}
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="d-inline-block">
            <Link to={`?class=${this.state.classSelected}&spec=${value.split('-').slice(1).join('-')}`}>
              <Icon
                key={index}
                slot={value}
                onClick={() => {
                  this.handleSpecClick({ value })
                }}
                iconSelected={this.state.specSelected}
              />
            </Link>
          </div>
        </OverlayTrigger>
      );
    }, this);

    var factions = this.factionValues().map((value, index) => {
      return (
        <OverlayTrigger
          key={index}
          placement="top"
          delay={1500}
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="d-inline-block">
            <Icon
              key={index}
              slot={value}
              onClick={() => {
                this.handleFactionClick({ value })
              }}
              iconSelected={this.state.factionSelected}
            />
          </div>
        </OverlayTrigger>
      );
    }, this);

    var races = this.raceValues().map((value, index) => {
      return (
        <OverlayTrigger
          key={index}
          placement="top"
          delay={1500}
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="d-inline-block">
            <Icon
              key={index}
              slot={value}
              onClick={() => {
                this.handleRaceClick({ value })
              }}
              iconSelected={this.state.raceSelected}
            />
          </div>
        </OverlayTrigger>
      );
    }, this);

    const toUpperCase = (word) => word.charAt(0).toUpperCase() + word.slice(1)
    const prettyClass = this.state.classSelected ? toUpperCase(this.state.classSelected) : null
    const prettySpec = this.state.specSelected  ? this.state.specSelected.split('-').slice(1).map(toUpperCase).join(' ') : null

    return (
      <div className="Selection">
        <Helmet>
          <title>WoW Classic {prettySpec ? `${prettySpec} ` : ''}{prettyClass ? `${prettyClass} ` : ''}BiS (Best in Slot) - WoW Classic BiS</title>
          <meta
            name="description"
            content={`Find the best in slot gear for ${prettySpec ? `${prettySpec} ` : ''}${prettyClass ? `${prettyClass}'s` : 'every class'} in World of Warcraft Classic at WoW Classic BiS.`}
          />
        </Helmet>

        <div className="stageSelection mt-2">
          STAGE{" "}
          <span className="spanLink" onClick={this.handleShowStageModal}>
            (description here)
          </span>
          <br />
          <ToggleButtonGroup
            type="radio"
            name="options"
            className="btn-group-lg stageSelectionGroup"
            value={this.state.stageSelected}
            onChange={this.handleChange}
          >
            {stages}
          </ToggleButtonGroup>
        </div>
        <div className="classSelection mt-2">
          CLASS <br />
          {classs}
        </div>
        <div className="specAndFactionSelection">
          {specs.length == 0 ? (
            <div className="specSelection mt-2" />
          ) : (
            <div className="specSelection mt-2 animated fadeInLeft">
              SPECIALIZATION
              <br />
              {specs}
            </div>
          )}
          {races.length == 0 ? (
            <div className="raceSelection mt-2" />
          ) : (
            <div className="raceSelection mt-2 animated fadeInRight">
              RACE
              <br />
              {races}
            </div>
          )}
          {factions.length == 0 ? (
            <div className="factionSelection mt-2" />
          ) : (
            <div className="factionSelection mt-2 animated fadeInRight">
              FACTION
              <br />
              {factions}
            </div>
          )}
        </div>
        <Modal
          show={this.state.showStageModal}
          onHide={this.handleCloseStageModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Stages</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Phase 1 (Classic Launch)</h4>
            <p>
              Molten Core
              <br />
              Onyxia
              <br />
              Maraudon
              <br />
              You can PvP one another in the world, but there is no tracking,
              and no formal rewards for doing so.
            </p>
            <h4>Phase 2</h4>
            <p>
              Dire Maul
              <br />
              Azuregos
              <br />
              Kazzak
              <br />
              Honor System (including Dishonorable Kills)
              <br />
              PvP Rank Rewards
            </p>
            <h4>Phase 3</h4>
            <p>
              Blackwing Lair
              <br />
              Darkmoon Faire
              <br />
              Darkmoon deck drops begin
              <br />
              Alterac Valley (version 1.12)
              <br />
              Warsong Gulch
            </p>
            <h4>Phase 4</h4>
            <p>
              Zul’Gurub
              <br />
              Green Dragons
              <br />
              Arathi Basin
            </p>
            <h4>Phase 5</h4>
            <p>
              Ahn’Qiraj War Effort begins
              <br />
              Ahn’Qiraj raids open when the war effort dictates
              <br />
              Dungeon loot reconfiguration: Tier 0.5 Dungeon gear, Relics, drop
              rates and location changes
            </p>
            <h4>Phase 6</h4>
            <p>
              Naxxramas
              <br />
              Scourge Invasion
              <br />
              World PvP objectives in Silithus and Eastern Plaguelands
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Selection)
