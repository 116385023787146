import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import Modal from "react-bootstrap/Modal";
import { BrowserRouter as Router } from 'react-router-dom'

import Tooltip from "./components/tooltip";
import Item from "./components/item";
import Selection from "./components/selection";
import BisList from "./components/bislist";
import Banner from "./components/banner";
import ItemTable from "./components/item-table";

import "./styles.css";
import "./wowhead.css";

import wow_classic_logo from "./img/wow_classic.jpg";
import discord_logo from "./img/discord.png";
import wt_logo from "./img/wtfooterlogo.png";
import bc_logo from "./img/bchat1.png";

function GetIdItemsFromSet(currentSet) {
  for (var i = 0; i < BisList.data.length; i++) {
    if (BisList.data[i].id == currentSet) {
      return BisList.data[i];
    }
  }
  var defaultitem = {
    id: 0,
    faction: "",
    wowclass: "",
    wowspec: "",
    name: "",
    stage: 0,
    head_item: 0,
    neck_item: 0,
    shoulder_item: 0,
    back_item: 0,
    chest_item: 0,
    wrist_item: 0,
    hand_item: 0,
    waist_item: 0,
    leg_item: 0,
    foot_item: 0,
    finger1_item: 0,
    finger2_item: 0,
    trinket1_item: 0,
    trinket2_item: 0,
    mainhand_item: 0,
    offhand_item: 0,
    range_item: 0,
    head_enchant: 0,
    shoulder_enchant: 0,
    back_enchant: 0,
    chest_enchant: 0,
    wrist_enchant: 0,
    hand_enchant: 0,
    leg_enchant: 0,
    feet_enchant: 0,
    mainhand_enchant: 0,
    offhand_enchant: 0,
    range_enchant: 0
  };
  return defaultitem;
}

ReactGA.initialize("UA-137996462-1");
ReactGA.pageview("/homepage");

class App extends React.Component {
  state = {
    itemSetId: null
  };
  setitemSet(id) {
    Tooltip.setTooltip(null);
    this.setState({ itemSetId: id });
  }
  constructor(props) {
    super(props);
    this.state = {
      itemSetId: null,
      showCreditsModal: false,
      showUsefullWebsitesModal: false,
      title: 'WoW Classic Best in Slot (BiS)'
    };
    this.setTitle = this.setTitle.bind(this);
    this.setitemSet = this.setitemSet.bind(this);
    this.handleShowCreditsModal = this.handleShowCreditsModal.bind(this);
    this.handleCloseCreditsModal = this.handleCloseCreditsModal.bind(this);
    this.observer = null;
  }
  handleCloseCreditsModal() {
    this.setState({ showCreditsModal: false });
  }
  handleShowCreditsModal() {
    this.setState({ showCreditsModal: true });
  }
  setTitle(title) {
    this.setState({ title: title });
  }
  componentDidMount() {
    const handleTitleChange = () => {
      const titleSplit = document.title.split(' - ')
      if (titleSplit.length > 1) {
        this.setTitle(titleSplit[0]);
      } else {
        this.setTitle(document.title);
      }
    };

    this.observer = new MutationObserver(handleTitleChange);

    const titleElement = document.querySelector('title');
    if (titleElement) {
      this.observer.observe(titleElement, { childList: true });
    }
  }
  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    const { itemSetId, title } = this.state;
    var itemset = GetIdItemsFromSet(itemSetId);
    return (
      <div className="App">
        <div className="WCTHeader">
          <h1 className="ast-advanced-headers-title">
            {title}
          </h1>
        </div>
        <div className="BisList">
          <div className="row">
            <Router basename={'/wow-classic/tools/bis'}>
              <Selection setItemSet={this.setitemSet} setTitle={this.setTitle} />
            </Router>
			<div className="BissListBottom">
            <Item
              slot="mainhand_item"
              item_id={itemset.mainhand_item}
              enchant_id={itemset.mainhand_enchant}
            />
            <Item
              slot="offhand_item"
              item_id={itemset.offhand_item}
              enchant_id={itemset.offhand_enchant}
            />
            <Item
              slot="range_item"
              item_id={itemset.range_item}
              enchant_id={itemset.range_enchant}
            />
			</div>
            <div className="BisListLeft">
              <Item
                slot="head_item"
                item_id={itemset.head_item}
                enchant_id={itemset.head_enchant}
              />
              <Item slot="neck_item" item_id={itemset.neck_item} />
              <Item
                slot="shoulder_item"
                item_id={itemset.shoulder_item}
                enchant_id={itemset.shoulder_enchant}
              />
              <Item
                slot="back_item"
                item_id={itemset.back_item}
                enchant_id={itemset.back_enchant}
              />
              <Item
                slot="chest_item"
                item_id={itemset.chest_item}
                enchant_id={itemset.chest_enchant}
              />
              <Item
                slot="wrist_item"
                item_id={itemset.wrist_item}
                enchant_id={itemset.wrist_enchant}
              />
            </div>
            <div className="BisListCenter">
              <Tooltip />
            </div>
            <div className="BisListRight">
              <Item
                slot="hand_item"
                item_id={itemset.hand_item}
                enchant_id={itemset.hand_enchant}
              />
              <Item slot="waist_item" item_id={itemset.waist_item} />
              <Item
                slot="leg_item"
                item_id={itemset.leg_item}
                enchant_id={itemset.leg_enchant}
              />
              <Item
                slot="foot_item"
                item_id={itemset.foot_item}
                enchant_id={itemset.foot_enchant}
              />
              <div className="BisListFingers">
                <Item slot="finger1_item" item_id={itemset.finger1_item} />
                <Item slot="finger2_item" item_id={itemset.finger2_item} />
              </div>
              <div className="BisListTrinkets">
                <Item slot="trinket1_item" item_id={itemset.trinket1_item} />
                <Item slot="trinket2_item" item_id={itemset.trinket2_item} />
              </div>
            </div>
            <div className="BisListTable">
              <ItemTable itemset={itemset} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
